.Footer-logo {
    height: 40vmin;
    pointer-events: none;
  }

  .Copyright {
      background-color: rgb(14, 12, 12);
      position: absolute;
      width: 100vw;
      margin: 0!important;
      bottom: 3px;
      left: -2px;
      box-sizing: border-box;
      padding: 0.5rem;
      box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.932);
  }

.Copyright a {
    margin: 0 0.3rem;
    color: rgb(88, 88, 255);
    font-weight: bolder;
    text-decoration: none;
}

.Copyright a:hover {
    color: rgb(148, 148, 255);
}


@media (prefers-reduced-motion: no-preference) {
    .Footer-logo {
        animation: Footer-logo-spin infinite 5s linear;
    }
}

@keyframes Footer-logo-spin {
    from {
        transform: rotateY(0deg);
    }
    to {
        transform: rotateY(360deg);
    }
}
